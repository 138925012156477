import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import styled from "styled-components";
import { useParams } from "react-router-dom";

const Wrapper = styled.main`
  flex: 1 1 auto;
`;
const Section = styled.section``;
const TopContainer = styled.div`
  width: 100%;
  height: 675px;
  margin: 0 auto;
  padding: 0 20px;
  background: #f9efe7;
`;
const ContentContainer = styled.div`
  width: 100%;
  padding: 170px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TextContainer = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;
const Lead = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 109.3%;
  /* or 15px */

  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: rgba(0, 0, 0, 0.35);
`;
const Title = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 76px;
  text-align: center;
  margin: 8px 0 37px 0;
  color: #000000;
  @media screen and (max-width: 769px) {
    line-height: 130%;
    font-size: 40px;
  }
  @media screen and (max-width: 450px) {
    font-size: 30px;
  }
`;
const Desc = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  /* or 32px */

  text-align: center;

  margin-bottom: 30px;

  color: #000000;
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`;
const VisitLink = styled.a`
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  text-transform: lowercase;

  /* Text Color */

  color: #000000;
  border-bottom: 2px solid #ffe872;
  padding: 5px;
  &:hover {
    background: #ffe872;
    transition: all ease 0.3s;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`;
const BottomContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
`;
const ImageContainer = styled.div`
  max-width: 930px;
  margin: 0 auto;
  margin-top: -163px;
  padding-bottom: 163px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: (0px 0px 30px rgba(0, 0, 0, 0.1));
  // overflow: hidden;
`;
const Image = styled.img`
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
`;
const ProjectPage = ({ data }) => {
  const { id } = useParams();
  console.log(id);
  const project = data.find((project) => {
    return project.id === id;
  });
  console.log(project);
  return (
    <Wrapper>
      <Section>
        <Navbar />
        <TopContainer>
          <ContentContainer>
            <TextContainer>
              <Lead>{project.lead}</Lead>
              <Title>{project.title}</Title>
              <Desc>{project.description}</Desc>

              <VisitLink href={project.link} target="_blank">
                Visit live site
              </VisitLink>
            </TextContainer>
          </ContentContainer>
        </TopContainer>
        <BottomContainer>
          <ImageContainer>
            <Image src={project.image} alt={project.title} />
          </ImageContainer>
        </BottomContainer>

        <Footer />
      </Section>
    </Wrapper>
  );
};

export default ProjectPage;
