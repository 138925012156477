import React from "react";
import styled from "styled-components";
import { BsGithub, BsLinkedin, BsTwitter } from "react-icons/bs";

import { MdCopyright } from "react-icons/md";
const Section = styled.section`
  width: 100%;
  // height: auto;
  background-color: #f9efe7;
  padding: 50px 0 24px 0;
`;
const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
`;
const Rows = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(525px, 1fr));
  gap: 60px;
  margin-bottom: 160px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const Row = styled.div``;
const SocialLinks = styled.ul`
  display: flex;
  align-items: center;
`;
const Social = styled.li`
  margin-right: 30px;
  &:last-of-type {
    margin-right: 0;
  }
`;
const SocialLink = styled.a`
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: #03ffc5;
    transitions: all ease 0.3s;
  }
`;
const Copyright = styled.p``;
const Title = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 76px;
  /* identical to box height */

  /* Text Color */

  color: #000000;
  @media screen and (max-width: 769px) {
    font-size: 40px;
  }
  @media screen and (max-width: 769px) {
    font-size: 30px;
  }
`;
const Desc = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  color: #000000;
`;
const EmailLink = styled.a`
  cursor: pointer;
  font-weight: 700;
`;
const Footer = () => {
  return (
    <Section>
      <Container>
        <Rows>
          <Row>
            <Title>Want to Chat ?</Title>
          </Row>
          <Row>
            <Desc>
              If you need a modern and powerful website for your business,
              startup or personally, I am available for work. You can email me
              directly at <EmailLink>anthonynwaweru@gmail.com.</EmailLink>
            </Desc>
          </Row>
        </Rows>
        <Rows>
          <Row>
            <Copyright>
              <MdCopyright /> {new Date().getFullYear()} Designed and built by
              Anthony Waweru
            </Copyright>
          </Row>
          <Row>
            <SocialLinks>
              <Social>
                <SocialLink
                  href="https://github.com/anthonynwaweru"
                  target="_blank"
                >
                  <BsGithub />
                </SocialLink>
              </Social>
              <Social>
                <SocialLink
                  href="https://www.linkedin.com/in/anthonywawerunjeri/"
                  target="_blank"
                >
                  <BsLinkedin />
                </SocialLink>
              </Social>
              <Social>
                <SocialLink
                  href="https://twitter.com/tonywaweru_"
                  target="_blank"
                >
                  <BsTwitter />
                </SocialLink>
              </Social>
            </SocialLinks>
          </Row>
        </Rows>
      </Container>
    </Section>
  );
};

export default Footer;
