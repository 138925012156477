import React from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const Section = styled.section`
  width: 100vw;
`;
const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 150px 20px;
  @media screen and (max-width: 769px) {
    padding: 100px 20px;
  }
`;

const Rows = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(525px, 1fr));

  gap: 60px;
  @media screen and(max-width:450px) {
    grid-template-columns: 1fr);
  }
`;
const Row = styled.div`
  @media screen and (max-width: 769px) {
    order: ${(props) => (props.column === "two" ? 1 : 2)};
  }
`;
const Lead = styled.h1`
  font-family: "Open sans", san-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */

  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #b7b4b9;
  color: ${(props) =>
    props.children.toLowerCase() === "latest work" ? "#b7b4b9" : "red"};
`;
const Title = styled.h1`
  font-family: "Abril Fatface";
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 76px;
  /* identical to box height */

  /* Text Color */

  color: #000000;
  @media screen and (max-width: 769px) {
    line-height: 130%;
    font-size: 40px;
  }
  @media screen and (max-width: 450px) {
    font-size: 30px;
  }
`;
const Process = styled.div`
  margin-top: 20px;
  display: flex;
`;
const Design = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;

  /* Text Color */

  color: #000000;
  padding: 6px 10px;
  background-color: #f9efe7;
  margin-right: 16px;
  &:last-of-type {
    margin-right: 0;
  }
`;
const Desc = styled.p`
  margin: 32px 0 64px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  /* or 32px */

  /* Text Color */
  max-width: 480px;
  color: #000000;
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`;
const BtnContainer = styled.div`
  width: 210px;
  height: 59px;
  border: 1px solid black;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  height: auto;
`;

const translate = keyframes`
from{
  transform:translateY(0px)
}
to{
  transform: translateY(-200px)
}`;
const ImageContainer = styled.div`
  width: 100%;
  box-shadow: 2px 2px 5px 21px rgba(249, 239, 231, 0.75);
`;
const ImageRow = styled.div`
  //   border: 1px solid #f9efe7;
  cursor: pointer;
  width: 100%;
  background-color: ##f9efe7;
  height: 446px;
  overflow: hidden;
  &:hover ${Image} {
    animation: ${translate} 2s linear infinite alternate;
  }
  &:hover {
    border: 1px solid #ffe872;
  }

  @media screen and (max-width: 450px) {
    margin-bottom: 35px;
  }
`;

const BtnLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 210px;
  height: 59px;
  text-align: center;
  background: black;
  color: white;
  border: 0;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  box-shadow: 0px 0px 3px;
  position: absolute;
  top: -10px;
  right: 8px;
  &:hover {
    top: -1px;
    right: -1px;
    border: 2px solid black;
    transition: all ease 0.3s;
  }
`;
const Work = ({ details }) => {
  return (
    <Section id="work">
      <Container>
        <Rows>
          <Row column="one">
            <Lead>{details.lead}</Lead>
            <Title>{details.title}</Title>
            <Process>
              <Design>{details.design}</Design>
              <Design>{details.development}</Design>
              <Design>{details.concept}</Design>
            </Process>
            <Desc>{details.desc}</Desc>
            <BtnContainer>
              <BtnLink to={`projects/${details ? details.id : "#"}`}>
                see this project
              </BtnLink>
            </BtnContainer>
          </Row>
          <Row column="two">
            <ImageContainer>
              <ImageRow>
                <Image src={details.img} alt={details.title} />
              </ImageRow>
            </ImageContainer>
          </Row>
        </Rows>
      </Container>
    </Section>
  );
};

export default Work;
