import React from "react";
import styled from "styled-components";
import { IoLogoCss3 } from "react-icons/io";
import { FaReact, FaHtml5, FaNodeJs, FaPython } from "react-icons/fa";
import { SiWebflow, SiWordpress, SiJavascript } from "react-icons/si";

const Section = styled.section`
  // width: 100vw;
  padding: 50px 0;
  @media screen and (max-width: 400px) {
    padding: 25px 0 30px 0;
  }
`;
const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;
const Rows = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;
const SkillsRow = styled.div`
  width: 100%;
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
`;
const Icon = styled.div``;
const Title = styled.h2`
  color: #ffffff;
  font-size: 18px;
`;
const Skills = () => {
  return (
    <Section>
      <Container>
        <Rows>
          <SkillsRow>
            <Icon>
              <SiJavascript style={{ color: "#FCDC00", fontSize: "20px" }} />
            </Icon>
            <Title>JavaScript</Title>
          </SkillsRow>
          <SkillsRow>
            <Icon>
              <FaReact style={{ color: "#51dbfb", fontSize: "20px" }} />
            </Icon>
            <Title>React</Title>
          </SkillsRow>
          <SkillsRow>
            <Icon>
              <FaHtml5 style={{ color: "#e34c26", fontSize: "20px" }} />
            </Icon>
            <Title>HTML</Title>
          </SkillsRow>
          <SkillsRow>
            <Icon>
              <IoLogoCss3 style={{ color: "#264de4", fontSize: "20px" }} />
            </Icon>
            <Title>CSS</Title>
          </SkillsRow>
          <SkillsRow>
            <Icon>
              <SiWebflow style={{ color: "#264de4", fontSize: "20px" }} />
            </Icon>
            <Title>Webflow</Title>
          </SkillsRow>
          <SkillsRow>
            <Icon>
              <SiWordpress style={{ color: "#2965f1", fontSize: "20px" }} />
            </Icon>
            <Title>WordPress</Title>
          </SkillsRow>
          <SkillsRow>
            <Icon>
              <FaNodeJs style={{ color: "3C873A", fontSize: "20px" }} />
            </Icon>
            <Title>NodeJs</Title>
          </SkillsRow>
          <SkillsRow>
            <Icon>
              <FaPython style={{ color: "#FFE873", fontSize: "20px" }} />
            </Icon>
            <Title>Python</Title>
          </SkillsRow>
        </Rows>
      </Container>
    </Section>
  );
};

export default Skills;
