import React, { useState } from "react";
import styled from "styled-components";
import logo from "../assets/shared/anthonylogo.svg";
import { Link as LinkS } from "react-scroll";
import { NavLink as LinkR, NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = styled.header`
  width: 100%;
  height: 80px;
  padding: 32px 0;
  background: #f9efe7;
  background-size: cover;
  @media screen and (max-width: 769px) {
    padding: 5px 0 50px 0;
    // position: fixed;
    background: #f9efe7;
    z-index: 999;
  }
`;
const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
`;
const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const NavBrand = styled.div`
  width: 175px;
  height: 25px;
`;
const Logo = styled.img`
  width: 100%;
  height: 100%;
`;
const NavItems = styled.ul`
  display: flex;
  align-items: center;
  @media screen and (max-width: 770px) {
    display: none;
  }
`;
const NavItem = styled.li`
  margin-right: 47px;
  &:last-of-type {
    margin-right: 0;
  }
  @media screen and (max-width: 769px) {
    margin-right: 0;
  }
`;
const Navlink = styled(LinkS)`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  cursor: pointer;
  text-transform: lowercase;
  color: #000000;
  border-bottom: 2px solid #ffe872;
  padding-bottom: 5px;
  &:hover,
  &.active {
    background: #ffe872;
    padding: 5px 0;
    transition: background ease-in 0.3s;
  }
`;
const NavRoutingLink = styled(NavLink)`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  cursor: pointer;
  text-transform: lowercase;
  color: #000000;
  border-bottom: 2px solid #ffe872;
  padding-bottom: 5px;
  &:hover,
  &.active {
    background: #ffe872;
    padding: 5px 0;
    transition: background ease-in 0.3s;
  }
`;
const MobileMenu = styled.div`
  position: absolute;
  width: 65%;
  right: 0px;
  top: ${(props) => props.open && props.top};
  transform: ${(props) =>
    props.open ? "translateY(props.top)" : "translateY(-100vh)"};
  transition: transform ease-in 0.5s;
  // visibility: ${(props) => (props.open ? "visible" : "hidden")};
  border-radius: 4px;
  background: #b7b4b9;
  padding: 20px;
  z-index: 999;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const MobileNavItems = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  & ${NavRoutingLink}, & ${Navlink} {
    border-bottom: 0;
    &:hover,
    &.active {
      background: #ffffff;
    }
  }
`;

const NavLogoLink = styled(LinkR)``;
const HamburgerBtn = styled.button`
  cursor: pointer;
  border: 0;
  color: #000000;
  font-size: 20px;
  background: ${(props) => (props.open ? "#b7b4b9" : "0")};
  transition: all ease 0.2s;
  padding: 20px;

  @media screen and (min-width: 770px) {
    display: none;
  }
`;
const Navbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Header>
      <Container>
        <NavContainer>
          <NavBrand>
            <NavLogoLink to="/">
              <Logo src={logo} alt="anthony waweru" />
            </NavLogoLink>
          </NavBrand>
          <NavItems>
            {props.projects && (
              <NavItem>
                <Navlink
                  activeClass="active"
                  to="work"
                  spy={true}
                  hashSpy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  isDynamic={true}
                >
                  Projects
                </Navlink>
              </NavItem>
            )}
            {props.about && (
              <NavItem>
                <Navlink
                  activeClass="active"
                  to="about"
                  spy={true}
                  hashSpy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  isDynamic={true}
                >
                  About
                </Navlink>
              </NavItem>
            )}
            <NavItem>
              <NavRoutingLink to="/contact">Contact</NavRoutingLink>
            </NavItem>
          </NavItems>
          <MobileMenu top="48px" open={isOpen}>
            <MobileNavItems>
              {props.projects && (
                <NavItem>
                  <Navlink
                    activeClass="active"
                    to="work"
                    spy={true}
                    hashSpy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    isDynamic={true}
                    onClick={handleMenuClick}
                  >
                    Projects
                  </Navlink>
                </NavItem>
              )}
              {props.about && (
                <NavItem>
                  <Navlink
                    activeClass="active"
                    to="about"
                    spy={true}
                    hashSpy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    isDynamic={true}
                    onClick={handleMenuClick}
                  >
                    About
                  </Navlink>
                </NavItem>
              )}
              <NavItem>
                <NavRoutingLink to="/contact">Contact</NavRoutingLink>
              </NavItem>
            </MobileNavItems>
          </MobileMenu>
          <HamburgerBtn open={isOpen} onClick={handleMenuClick}>
            <GiHamburgerMenu />
          </HamburgerBtn>
        </NavContainer>
      </Container>
    </Header>
  );
};

export default Navbar;
