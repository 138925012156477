import React from "react";
import styled from "styled-components";

const Section = styled.section`
  padding: 0 0 140px 0;
  width: 100vw;
`;
const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
`;
const Rows = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
`;
const Row = styled.div`
  padding-top: 30px;
  @media screen and (max-width: 400px) {
    padding-bottom: 15px;
  }
`;
const Top = styled.div`
  margin-bottom: 80px;
  @media screen and (max-width: 400px) {
    margin-bottom: 40px;
  }
`;
const Bottom = styled.div``;
const Title = styled.h1`
  font-family: "Abril Fatface";
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 76px;
  color: #000000;
  @media screen and (max-width: 769px) {
    font-size: 40px;
  }
  @media screen and (max-width: 769px) {
    font-size: 30px;
  }
`;
const Lead = styled.h2`
  font-famil: "Open sans" sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 130%;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 9px;
`;
const Desc = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
`;

const About = () => {
  return (
    <Section id="about">
      <Container>
        <Rows>
          <Row>
            <Title>What I do</Title>
            {/* <Image src={backgroundPic} alt="tools" /> */}
          </Row>
          <Row>
            <Top>
              <Lead>Design</Lead>
              <Desc>
                I design beautiful and powerful websites for modern businesses.
                Any business today needs a website that wins customers’ trust
                and helps you do your business well. I make sure your website is
                up to that standard.
              </Desc>
            </Top>
            <Bottom>
              <Lead>Development</Lead>
              <Desc>
                I build websites and in: React, JavaScript, HTML, CSS, SASS,
                Wordpress and Webflow, on the Front-end where I can create
                responsive, powerful and fully custom websites. On the back-end
                I'm well versed with NodeJS, MongoDB, Express and Postman. I
                worked based on the clients needs but I always offer advice and
                suggestions when needed
              </Desc>
            </Bottom>
          </Row>
        </Rows>
      </Container>
    </Section>
  );
};

export default About;
