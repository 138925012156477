export const data = [
  {
    id: "audiophile",
    lead: "Project Showcase",
    title: "Audiophile E-commerce",
    description:
      "As a personal portfolio project, Audiophile E-commerce showcases my skills in building an intuitive e-commerce platform dedicated to audio enthusiasts. Leveraging technologies like ReactJS, Redux, and styled-components, I created a sleek and user-friendly interface for browsing and purchasing high-quality audio products.",
    link: "https://audiophile-ecommerce-navy.vercel.app/",
    image: require("../assets/images/audiophile.png"),
  },
  {
    id: "chatapp",
    lead: "Project Showcase",
    title: "Chat App",
    description:
      "This is a homepage design and build for a concept project – a chat application. I designed the page first then later built a responsive page in Webflow.",
    link: "https://chat-app-home-page-60f3d4.webflow.io/",
    image: require("../assets/images/ChatAppwebsite.png"),
  },
  {
    id: "devfinder",
    lead: "Project Showcase",
    title: "Devfinder App ",
    description:
      "Devfinder is portfolio project website - A 3rd-party API web page. The website uses the GitHub users API to pull a user's profile data and display it.",
    link: "https://scintillating-melomakarona-39ef86.netlify.app/",
    image: require("../assets/images/devfinder.png"),
  },
  {
    id: "easybank",
    lead: "Project Showcase",
    title: "Easy Bank Landing Page",
    description:
      "This is a landing page design and build for a concept project - an online banking website. The Figma designs were provided then I built a responsive page with HTML, SASS and JavaScript.",
    link: "https://benevolent-griffin-d83450.netlify.app/",
    image: require("../assets/images/Easybanklandingpage.png"),
  },
  {
    id: "sneakers",
    lead: "Project Showcase",
    title: "E-Commerce Landing Page",
    description:
      "This was a concept product page for an e-commerce website - An e-commerce product website page. I translated the designs from figma to the website using HTML, CSS and JavaScript.",
    link: "https://zealous-edison-de12bb.netlify.app/",
    image: require("../assets/images/ecommerceproductpage.png"),
  },
  {
    id: "mangestore",
    lead: "Project Showcase",
    title: "Mange-Store",
    description:
      "This is an online grocery store supermarket website, built with react and styled-components. The store utilizes 2 different API endpoints to fetch DATA, one for listing the products and the other for listing the details of a specific product. I have also implemented & integrated Stripe Checkout which allows the customer to pay for the items in their cart during checkout.",
    link: "https://papaya-churros-7b8cfb.netlify.app/",
    image: require("../assets/images/mangestore.png"),
  },
  {
    id: "restcountries",
    lead: "Project Showcase",
    title: "Rest Countries",
    description:
      "Rest countries is a single page site that utilizes the Rest-countries API to display a list of countries, search for a specific country and filter countries based on region(continent), as well as see detailed information of the country.",
    link: "https://tiny-cat-0837cb.netlify.app/",
    image: require("../assets/images/restcountries.png"),
  },
  {
    id: "sunnyside",
    lead: "Project Showcase",
    title: "Sunny Side Landing Page",
    description:
      "A concept landing page design and build for a concept project - A creative agency Website. The Landing page is built with HTML, CSS and JavaScript.",
    link: "https://clinquant-crostata-4d246f.netlify.app/",
    image: require("../assets/images/Sunnysideagencylandingpage.png"),
  },
  {
    id: "anthonyportfolio",
    lead: "Project Showcase",
    title: "My Portfolio Site",
    description:
      "This is my personal portfolio page design and build. I designed the page first with Figma and the I built a responsive website with ReactJs and Styled-Components",
    link: "https://www.anthonynwaweru.com",
    image: require("../assets/images/anthonywaweru.png"),
  },
];
