import React, { useState } from "react";
import emailjs from "emailjs-com";
import styled from "styled-components";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useForm } from "react-hook-form";

const Wrapper = styled.main`
  flex: 1 1 auto;
`;
const Section = styled.section``;

const Container = styled.div`
  max-width: 1440px;

  margin: 0 auto;
  padding: 40px 20px 40px 20px;
  margin-bottom: 60px;
  @media screen and (max-width: 450px) {
    margin-bottom: 220px;
  }
`;
const Rows = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(525px, 1fr));
`;
const Row = styled.div`
  @media screen and (max-width: 769px) {
    max-width: 100%;
  }
  @media screen and (max-width: 450px) {
    max-width: 100%;
  }
`;
const Title = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 76px;
  /* identical to box height */

  /* Text Color */

  color: #000000;
  @media screen and (max-width: 769px) {
    max-width: 100%;
    font-size: 40px;
  }
  @media screen and (max-width: 450px) {
    font-size: 30px;
  }
`;
const Desc = styled.p``;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  // padding: 50px 0;
`;
const Label = styled.label`
  margin: 15px 0;
  font-family: inherit;
  font-size: 18px;
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`;
const Input = styled.input`
  width: 100%;
  padding: 20px;
  border: 2px solid #f0f0f0;
  &:focus {
    outline: 0;
    border-color: #ffe872;
  }
  @media screen and (max-width: 769px) {
    width: 85%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;
const Message = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 20px;
  border: 2px solid #f0f0f0;
  resize: none;
  &:focus {
    outline: 0;
    border-color: #ffe872;
  }
  @media screen and (max-width: 769px) {
    width: 85%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;
const Button = styled.button`
  margin-top: 15px;
  // margin-bottom: 10px;
  float: left;
  width: 169px;
  height: 70px;
  border: none;
  padding: 15px;
  background-color: #f9efe7;
  cursor: pointer;
  color: #00000;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  &:hover {
    background: #ffe872;
    transition: background ease 0.3s;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
    color: #000000;
  }
`;
const Span = styled.span`
  color: #ff8cb2;
  font-size: 13px;
  margin-top: 5px;
`;
const Contact = () => {
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

  const {
    register,
    handleSubmit,
    // watch,
    reset,
    formState: { errors },
    formState,
  } = useForm({
    defaultValues: {
      user_name: "",
      user_email: "",
      message: "",
    },
  });

  // console.log(watch());

  let verifyMessage =
    "Thank you for reaching out, I'll be getting back to you ASAP. ";

  const handleEmailSubmit = (formData, e) => {
    emailjs
      .sendForm(
        "service_102koq7",
        "template_4a3ukrm",
        e.target,
        "orOJdzxuwLAp3yflU"
      )
      .then(
        (result) => {
          // console.log(result.text);
          // console.log(result);
          setSubmittedSuccessfully(result.status === 200);
        },
        (error) => {
          console.log(error.text);
          verifyMessage = error.text;
        }
      );

    reset();
    setTimeout(() => {
      formState.isSubmitted && setSubmittedSuccessfully(false);
    }, 5000);
  };

  return (
    <>
      <Wrapper>
        <Section>
          <Navbar />
          <Container>
            <Rows>
              <Row>
                <Title>Want to work?</Title>
                <Desc></Desc>
              </Row>
              <Row>
                {formState.isSubmitted && submittedSuccessfully && (
                  <h2 style={{ color: "#03FFC5", marginBottom: "15px" }}>
                    {verifyMessage}
                  </h2>
                )}
                <Form onSubmit={handleSubmit(handleEmailSubmit)}>
                  <Label htmlFor="name">Name</Label>
                  <Input
                    {...register("user_name", {
                      required: "Your name is required",
                      minLength: {
                        value: 3,
                        message: "name must be at least 3 characters long",
                      },
                    })}
                    type="text"
                    id="name"
                    name="user_name"
                    disabled={formState.isSubmitting}
                    // onChange={(e) => setName(e.target.value)}
                  />
                  {<Span>{errors.user_name?.message}</Span>}
                  <Label htmlFor="email">Email</Label>
                  <Input
                    {...register("user_email", {
                      required: "Your email is required",
                    })}
                    type="email"
                    id="email"
                    name="user_email"
                    disabled={formState.isSubmitting}
                    // onChange={(e) => setEmail(e.target.value)}
                  />
                  <Span>{errors.user_email?.message}</Span>
                  <Label htmlFor="message">Message</Label>
                  <Message
                    {...register("message", {
                      required: "Your message is important",
                    })}
                    name="message"
                    id="message"
                    disabled={formState.isSubmitting}
                    // onChange={(e) => setSentMessage(e.target.value)}
                  />
                  <Span>{errors.message?.message}</Span>

                  <Button
                    type="submit"
                    value="send"
                    disabled={formState.isSubmitting}
                  >
                    Send
                  </Button>
                </Form>
              </Row>
            </Rows>
          </Container>
          <Footer />
        </Section>
      </Wrapper>
    </>
  );
};

export default Contact;
