import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import styled from "styled-components";
import ScrollToTopWrapper from "./components/ScrollToTopWrapper";
import ProjectPage from "./pages/ProjectPage.js";
import { data } from "./data/pageData.js";

const AppWrapper = styled.div`
  flex: 1 1 auto;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // max-width: 100%;
  position: relative;
`;
const Container = styled.div`
  // max-width: 100vw;
  display: flex;
  flex: 1 0 auto;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
`;

function App() {
  return (
    <AppWrapper>
      <Container>
        <BrowserRouter>
          <ScrollToTopWrapper>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/contact" element={<Contact />} />
              <Route
                path="/projects/:id"
                element={<ProjectPage data={data} />}
              />
            </Routes>
            {/* <GoogleTranslateWidget />*/}
          </ScrollToTopWrapper>
        </BrowserRouter>
      </Container>
    </AppWrapper>
  );
}

export default App;
