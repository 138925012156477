const data = [
  {
    id: "audiophile",
    lead: "latest work",
    title: "Audiophile E-commerce Shop",
    desc: "Audiophile is an e-commerce shop that offers customers products for all their audio needs. As a personal portfolio project, Audiophile E-commerce showcases my skills in building an intuitive e-commerce platform dedicated to audio enthusiasts. Leveraging technologies like ReactJS, Redux, and styled-components",
    design: "Project",
    development: "React, Redux, Styled-Components, React-Router-Dom",
    concept: "Portfolio Project",
    img: require("../assets/images/audiophile.png"),
    link: "audiophile",
    live: true,
  },
  {
    id: "restcountries",
    lead: "latest work",
    title: "Rest Countries",
    desc: "Rest countries is a single page site that utilizes the Rest-countries API to display a list of countries, search for a specific country and filter countries based on region(continent). ",
    design: "Project",
    development: "API, React, Tailwind-CSS, React-Router-Dom",
    concept: "Portfolio Project",
    img: require("../assets/images/restcountries.png"),
    link: "restcountries",
    live: true,
  },
  {
    id: "mangestore",
    lead: "latest work",
    title: "Mange Store",
    desc: "Mange-store is an online grocery store concept website. On the front-end the store is built with React and Styled-components and on the back-end the store utilizes 2 different API endpoints to fetch DATA, one for listing the products and the other for listing the details of a specific product. The store has also been integrated with the Stripe Checkout API which allows the customer to pay for the products on the Stripe Dashboard which the store processes during checkout.",
    design: "Project",
    development: "React/Styled-Components",
    concept: "Portfolio Project",
    img: require("../assets/images/mangestore.png"),
    link: "mangestore",
    live: true,
  },
  {
    id: "anthonyportfolio",
    lead: "latest work",
    title: "My Portfolio",
    desc: "Personal portfolio website, where I showcase some of the projects that I have worked on. The website is built with React and Styled-Components",
    design: "Website Design",
    development: "React/Styled-Components",
    concept: "Portfolio Page",
    img: require("../assets/images/anthonywaweru.png"),
    link: "myportfolio",
    live: true,
  },

  {
    id: "easybank",
    lead: "latest work",
    title: "Easy Bank",
    desc: "Easy bank is a concept landing page design for an online banking website platform. The landing page is built with HTML, SASS and JavaScript.",
    design: "Figma Design",
    development: "HTML, SASS & Js Development",
    concept: "concept",
    img: require("../assets/images/Easybanklandingpage.png"),
    link: "easybank",
  },
  {
    id: "devfinder",
    lead: "latest work",
    title: "DevFinder",
    desc: "Devfinder is portfolio project website that utilizes the Github 3rd-party API to fetch GitHub users profile data and display it on the page.",
    design: "Figma",
    development: "HTML, SASS, API, Js",
    concept: "Portfolio Project",
    img: require("../assets/images/devfinder.png"),
    link: "devfinder",
  },
  {
    id: "sunnyside",
    lead: "latest work",
    title: "Sunny Side Agency",
    desc: "Sunny-side agency is concept landing page design for a creative agency Website. The Landing page is built with HTML, CSS and JavaScript.",
    design: "Figma Design",
    development: "HTML, CSS & Js Development",
    concept: "concept",
    img: require("../assets/images/Sunnysideagencylandingpage.png"),
    link: "sunnyside",
  },
  {
    id: "sneakers",
    lead: "latest work",
    title: "E-commerce ",
    desc: "Sneakers e-commerce page is a concept product page for an e-commerce website. The page is built using HTML, CSS and JavaScript.",
    design: "Figma",
    development: "HTML, CSS & Js Development",
    concept: "concept",
    img: require("../assets/images/ecommerceproductpage.png"),
    link: "ecommerce",
  },
  {
    id: "chatapp",
    lead: "latest work",
    title: "Chat App ",
    desc: "Chat-message is a homepage design and build concept project for a chat application website. The homepage is built on Webflow.",
    design: "Website Design",
    development: "Webflow Development",
    concept: "concept",
    // img: require("../../public/images/ChatAppwebsite.png"),
    img: require("../assets/images/ChatAppwebsite.png"),
    link: "chatapp",
  },
  // {
  //   id: "Photo snap",
  //   lead: "Coming Soon",
  //   title: "Photo Snap ",
  //   desc: "Photo-snap is a concept project of a photo sharing website that I'm working on with HTML, CSS and JavaScript. To be updated soon!!!",
  //   design: "Figma Design",
  //   development: "HTML, CSS & Js Development",
  //   concept: "concept",
  //   img: require("../assets/images/photosnap.png"),
  //   link: "#",
  //   comingSoon: true,
  // },
];

export default data;
