import React from "react";
import { Link } from "react-scroll";
import styled, { keyframes } from "styled-components";
import developer from "../assets/shared/Website Creator-rafiki.svg";
import Navbar from "./Navbar";

const Section = styled.section`
  // width: 100%;
  height: 100vh;
  background-size: cover;
  background-color: #f9efe7;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;
const Container = styled.div`
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(525px, 1fr));
  align-items: center;
  justify-content: center;
  gap: 30px;
  @media screen and (max-width: 769px) {
    display: flex;
    align-items: center;
  }
`;
const LeftContent = styled.div`
  @media screen and (max-width: 769px) {
    flex: 0 0 100%;
    margin: auto;
  }
`;
const TitleWrapper = styled.div`
  width: 100%;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-family: "Abril Fatface";
  // width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 130%;
  color: #000000;
  @media screen and (max-width: 769px) {
    width: 100%;
    font-size: 50px;
  }
  @media screen and (max-width: 450px) {
    font-size: 30px;
  }
`;

const Desc = styled.p`
  margin: 64px 0;
  max-width: 496px;
  line-height: 32px;
  font-size: 18px;
  color: #000000;
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`;
const BtnContainer = styled.div`
  width: 210px;
  height: 59px;
  border: 1px solid black;
  position: relative;
  // @media screen and (max-width: 769px) {
  //   border: 0;
  // }
`;

const LinkBtn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 210px;
  height: 59px;
  text-align: center;
  background: black;
  color: white;
  border: 0;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  box-shadow: 0px 0px 3px;
  position: absolute;
  top: -10px;
  right: 8px;
  &:hover {
    top: -1px;
    right: -1px;
    border: 1px solid black;
    transition: all ease 0.3s;
  }
`;

const RightContent = styled.div`
  @media (max-width: 1110px) {
    display: none;
  }
`;
const Image = styled.img`
  width: 100%;
`;
const translate = keyframes`
from{
  transform:translate(-100%)
}
to{
  transform: translate(100%)
}
`;
const ScrollLink = styled(Link)``;
const ScrollLine = styled.div`
  width: 105px;
  height: 2px;
  background-color: #000000;
`;
const ScrollText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  margin-bottom: 23px;
`;
const ScrollDown = styled.div`
  position: absolute;
  top: -37px;
  right: 0;
  align-self: flex-end;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transform: rotate(90deg);
  overflow: hidden;
  width: 105px;
  &:hover ${ScrollLine} {
    animation: ${translate} 2s linear infinite;
  }
  @media screen and (max-width: 769px) {
    display: none;
  }
`;

const ScrollWrapper = styled.section`
  width: 100vw;
`;

const Hero = () => {
  return (
    <>
      <Section>
        <Navbar about="about" projects="projects" />
        <Container>
          <Content>
            <LeftContent>
              <TitleWrapper>
                <Title>
                  Hi, I'm Anthony. Front-End Developer and Web Designer.
                </Title>
              </TitleWrapper>
              <Desc>
                I design and build beautiful websites and web-applications that
                offer great user experience. Naturally curious and continously
                learning and challenging myself into leveling up my skills and
                craft. If you need a modern and powerful website, send me an
                email and If we are a good fit, we can schedule a call.
              </Desc>
              <BtnContainer>
                <LinkBtn
                  to="work"
                  spy={true}
                  smooth={true}
                  hashSpy={true}
                  offset={50}
                  duration={500}
                >
                  see my work
                </LinkBtn>
              </BtnContainer>
            </LeftContent>
            <RightContent>
              <Image src={developer} alt="storyset" />
            </RightContent>
          </Content>
        </Container>
      </Section>
      <ScrollWrapper>
        <Container>
          <ScrollDown>
            <ScrollLink
              to="work"
              spy={true}
              smooth={true}
              hashSpy={true}
              offset={50}
              duration={500}
            >
              <ScrollText>Scroll</ScrollText>
              <ScrollLine></ScrollLine>
            </ScrollLink>
          </ScrollDown>
        </Container>
      </ScrollWrapper>
    </>
  );
};

export default Hero;
