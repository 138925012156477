import React from "react";
import data from "../data/data";
import Work from "./Work";
const WorkList = () => {
  return (
    <div>
      {data.map((detail) => (
        <Work key={detail.id} details={detail} />
      ))}
    </div>
  );
};

export default WorkList;
