import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Skills from "../components/Skills";
import Footer from "../components/Footer";
import WorkList from "../components/WorkList";
import styled from "styled-components";

const Container = styled.div``;
const Home = () => {
  return (
    <Container>
      <Hero />
      <WorkList />
      <About />
      <Skills />
      <Footer />
    </Container>
  );
};

export default Home;
